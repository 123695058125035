
import css from './CRMPage1.module.css'

const CRMPage1 = () => {
    return ( 
<>
<section className={css.container}>
    <h1 className={css.header}>Разработка CRM-Систем</h1>
</section>
</>
     );
}
 
export default CRMPage1;