import css from './QuestionPage1.module.css'

const QuestionPage1 = () => {
    return ( 
<>
<section className={css.container}>
    <h1 className={css.header}>Вопросы</h1>
</section>
</>
     );
}
 
export default QuestionPage1;