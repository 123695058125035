import css from './DisignPage1.module.css'


const DisignPage1 = () => {
    return (
<>
<section className={css.container}>
    <h1 className={css.header}>Дизайн</h1>
</section>
</>
    );
}
 
export default DisignPage1;