import css from './MobilePage1.module.css'

const MobilePage1 = () => {
    return ( 
<>
<section className={css.container}>
    <h1 className={css.header}>Разработка Мобильных приложений</h1>
</section>
</>
     );
}
 
export default MobilePage1;