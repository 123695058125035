import css from './PromotionPage1.module.css'

const PromotionPage1 = () => {
    return (
<>
<section className={css.container}>
    <h1 className={css.header}>Продвижение</h1>
</section>
</>
    );
}
 
export default PromotionPage1;